/* src/Carousel.css */
.carousel {
  padding-right: 50px;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.carousel__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
}
.carousel__btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel__btn--prev {
  left: 0;
}
.carousel__btn--next {
  right: 0;
}
.carousel__img {
  width: 100%;

  display: block;

  transition: transform 1s ease-in-out;
  /* animation: fadeInAnimation 7s infinite; */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.carousel__img:hover {
  transform: scale(1.05);
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
