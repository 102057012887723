.header .menu-btn:checked ~ .menu {
  max-height: 500px; /* Adjust based on content height */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  transition: max-height 0.3s ease-out; /* Smooth transition */
}

.language-switcher-button {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  padding: 20px 20px;
  text-decoration: none;
}

.language-icon {
  margin-right: 8px; /* Space between the icon and text */
  font-size: 1.2em; /* Size of the icon */
}

.chevron-icon {
  margin-left: 8px; /* Space between the text and chevron */
  font-size: 1.2em; /* Size of the chevron */
  vertical-align: middle;
}

.language-switcher-button:hover {
  background-color: #a6e1fa;
  color: black;
}

.nav-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  background: rgb(237, 249, 254);
}

.header {
  display: block;
  max-width: 100%;
  padding: 5px 5%;
  margin: 0px auto;
  /* z-index: 10; */
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
}
.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 20px 20px;
  /* color: black; */
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  /* background-color: #A6E1FA; */
}

.header .logo {
  display: block;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
}
.header .logo-name {
  font-family: "Agustina Regular";
  font-weight: bold;
  padding: 0 10px;
  /* color: #55198b; */
}

/* menu */

.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* dropdown */

.dropdown-container {
  padding: 20px;
  color: rgb(0, 28, 85);
}

.dropdown {
  display: inline-flex;

  /* vertical-align: top; */
}

.button {
  color: rgb(0, 28, 85);
  border-width: 1px;

  cursor: pointer;

  text-align: center;
  white-space: nowrap;
}
.dropdown-menu {
  display: none;
  /* left: 0; */
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  background-color: rgb(237 249 254);
  /* animation-name: slideDown; */
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  z-index: 10;
}
.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}
.dropdown.is-active .dropdown-menu .button {
  font-weight: 700;
}

.dropdown-content {
  /* background-color: hsl(0, 0%, 100%); */
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);

  padding-top: 0.5rem;
}

.is-active {
  top: 0;
  left: 0;
  width: 100%;

  transition: all 0.3s ease-in-out;
}
@keyframes slideDown {
  from {
    top: -120px;
  }

  to {
    top: 0;
  }
}

.dropdown-item {
  color: hsl(0, 0%, 29%);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;

  position: relative;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}
