/* Experience.css */

/* Centering video and heading */
.video-header-container {
  text-align: center;
  margin: 60px 0;
}

.video-header-text {
  font-size: 36px;
  font-family: "Google Sans Regular";
  color: #fff; /* Adjust based on theme */
}

.video-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.intro-video {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  height: auto;
  border-radius: 8px;
}

/* YouTube Heading */
.yt-heading-container {
  text-align: center;
  margin: 20px 0;
}

/* General styles for the YouTube heading link */
.yt-heading-text-container a {
  text-decoration: none; /* Remove underline */
}

/* Optional: Hover effects for better UI */
.yt-heading-text-container a:hover {
  text-decoration: underline; /* Optionally, underline on hover */
}

/* Ensure text styling */
.yt-heading-text {
  font-size: 24px;
  font-family: "Google Sans Regular";
  color: #fff; /* Adjust based on theme */
  margin-top: 50px;
  text-align: center;
}

/* Styles for the YouTube button */
.youtube-button-container {
  text-align: center;
  margin: 40px 0;
}

.youtube-button {
  display: inline-block;
  background: none;
  border: none;
}

.youtube-logo {
  width: 200px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.3s ease;
}

.youtube-logo:hover {
  transform: scale(1.05);
}

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.greet-main {
  width: 100%;
  padding: 85px 5% 20px 5%;
  margin: 0px auto;
  /* margin-top: 2rem; */
  background: url(../../assests/images/backgorund.jpg) no-repeat center;
  background-size: cover;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  /* margin-bottom: 30px; */
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  color: rgb(0 28 85);
  /* color: white; */
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
  text-shadow: 1px 1px 2px white;
}
.greeting-back-title {
  /* color: rgb(0 28 85); */
  color: white;
  padding: 0px 20%;
  text-align: center;
  font-size: 50px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
  text-shadow: 0px 0px 5px darkblue, 0px 0px 10px rgb(3, 26, 71);
}
.greeting-nickname {
  font-size: 35px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-subjectname {
  font-size: 24px;
  margin: 0px;
  color: rgb(0 28 85);
  font-family: "Google Sans Bold Italic";
  text-shadow: 1px 1px 3px white;
}
.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
  margin-right: 40px;
  margin-bottom: 100px;
  text-align: center;
  color: rgb(0 28 85);
  font-family: "Google Sans Medium";
  text-shadow: 1px 1px 3px white;
}
/* General Styles */
.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

.greeting-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px; /* Space above the container */
}

.greeting-image-div .img-container {
  width: 100%;
  max-width: 500px; /* Adjust as needed */
  height: auto;
  display: flex;
  justify-content: center;
}

.greeting-image-div img {
  width: 100%; /* Ensure the image scales with its container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 30%;
}

/* Media Query for Medium Screens */
@media (max-width: 768px) {
  .greeting-image-div {
    margin-top: 30px; /* Adjust top margin for smaller screens */
  }

  .greeting-image-div .img-container {
    padding: 0 10px; /* Add some padding on smaller screens */
  }

  .greeting-image-div img {
    width: 100%; /* Ensure full width on smaller screens */
    margin-right: 0; /* Remove margin on smaller screens */
    border-radius: 20%; /* Adjust border radius if needed */
  }
}

/* Media Query for Small Screens */
@media (max-width: 480px) {
  .greeting-image-div {
    flex-direction: column; /* Stack elements vertically */
  }

  .greeting-image-div .img-container {
    width: 100%;
  }

  .greeting-image-div img {
    width: 100%; /* Ensure full width on extra small screens */
    margin: 0; /* Remove any extra margin */
    border-radius: 15%; /* Adjust border radius if needed */
  }
}


/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
