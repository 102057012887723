.divider-container {
  position: relative;
  margin: 0px;
  padding: 1px 5px;
  width: 100%;
  height: auto;
}

.divider-img {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: -1;
  left: 0px;
  top: 0px;
}
