.experience-accord {
  margin: 150px 5% 50px 5%;
}
.experience-accord li {
  text-align: center;
}
.section-title{
  text-align: center;
  margin-bottom: 60px;
}
.experience-accord li div {
  font-style: oblique;
  text-align: center;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
