.pull-requests-image {
  margin: 20px 0;
  text-align: center;
}

.pull-requests-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.pull-requests-intro {
  margin: 20px 0;
  font-size: 26px;
  line-height: 1.5;
  color: #333;
  text-align: left;
  margin-left: 80px;
}

.pull-requests-intro p {
  margin: 0;
}

.pull-requests-header-div {
  margin-top: 100px;
}

.pull-requests-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  text-shadow: 2px 2px 3px gray;
}

.pull-request-body-div {
  text-align: center;
  /* width: 100%; */
  margin-left: 5%;
  margin-right: 5%;
}

@media (max-width: 1380px) {
  .pull-requests-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .pull-requests-header-div {
    margin-top: 40px;
  }

  .pull-requests-header {
    font-size: 25px;
  }
}
