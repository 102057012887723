.App {
  text-align: center;
}

body {
  margin: 0px;
}
.App-logo {
  height: 40vmin;
}
#root {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.subTitle {
  color: #868e96;
}

#particles-js {
  background: #1488cc;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2b32b2, #1488cc);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2b32b2, #1488cc);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  display: flex;
  vertical-align: bottom;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#particles-js .particles-js-canvas-el {
  width: 100% !important;
  height: 100vh !important;
}

/* Animations */
@keyframes fade_down {
  0% {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@keyframes fade_up {
  0% {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
