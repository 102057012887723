.process-card {
  margin: 80px 240px 2rem;
  padding: 1rem;
  border: 1px solid #ffffff;
  border-radius: 12px;
  background: transparent;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.1), 0 8px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.8s ease, transform 0.8s ease;
}

.process-card:hover {
  box-shadow: 0 8px 16px rgba(139, 216, 255), 0 12px 32px rgba(139, 216, 255);
  transform: translateY(-4px);
}
.process-topic {
  font-weight: bold;
}

.process-keywords {
  font-style: italic;
}
.process-content {
  position: relative;
}

.process-image {
  float: right;
  width: 250px;
  height: auto;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border-radius: 4px;
}

.process-title,
.process-topic,
.process-abstract-heading,
.process-abstract,
.process-keywords-heading,
.process-keywords {
  margin: 20px;
}

.process-title,
.process-topic,
.process-abstract-heading,
.process-abstract,
.process-keywords-heading,
.process-keywords {
  margin: 1.5rem 0;
}
