.opensource-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 73px;
}

.background-section {
  background-image: url("../../assests/images/backgorund.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: rgb(39, 0, 80);
  text-align: center;
  padding-top: 0px;
  padding-bottom: 4rem;
}

.section-heading {
  font-size: 3rem;
  margin-top: 70px;
}

.book-covers {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  width: 100%; /* Use full width */
  max-width: 1200px;
  padding: 0 20px; /* Add padding to avoid content touching edges */
}

.book-info {
  text-align: left;
  flex: 1; /* Take up remaining space */
}

.book-info h2 {
  line-height: normal;
  margin: 0 0 4rem 0; /* Add space below each title */
  color: rgb(39, 0, 80);
  font-size: 2rem;
}

.phrase-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.phrase {
  color: white;
  text-align: center;
  font-size: 35px;
  line-height: normal;
  font-family: "Google Sans Bold";
  text-shadow: 0px 0px 5px darkblue, 0px 0px 10px rgb(3, 26, 71);
  margin-left: 40px;
  margin-right: 40px;
}

.process-section {
  text-align: center;
}

.author-image {
  width: 300px; /* Adjust width as needed */
  height: auto;
  margin-left: 6rem; /* Space between book info and image */
  border-radius: 50%; /* Make the image round */
  object-fit: cover; /* Ensure image covers the area without distortion */
}
