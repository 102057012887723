.studies-section {
    padding: 20px;
  }

.section-title{
    font-size: 40px;
    margin-bottom: 100px;
}
  .studies-heading {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
.category-title{
    font-size: 30px;
    margin: 40px 30px;
}
  
  .studies-list {
    list-style: none;
    padding: 0;
  }
  
  .study-item {
    text-align: left;
    font-size: 22px;
    margin: 20px 20;
  }
  
  .study-item strong {
    font-weight: bold;
  }
  
