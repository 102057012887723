.career-card-container {
  margin-top: 50px;
  width: 100%;
}

.career-card-content {
  width: 100%;
}

.career-card-elem {
  border: 1px solid rgb(197, 196, 196);
  box-shadow: 10px 10px 2px gray;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
}
.career-card-img {
  width: 300px;
  margin: auto;
}

.card-header-text {
  font-size: 28px;
  line-height: 1.1;
}
.card-text {
  color: rgb(127, 141, 170);
}
